import React from 'react';
import Box from '@webAtoms/Box/Box';
import Container from '@webAtoms/Container/Container';
import Accordion from '@webMolecules/Accordion/Accordion';
import PageHeading from '@webMolecules/PageHeading/PageHeading';
import UpNext from '@webMolecules/UpNext/UpNext';
import VisitOurDisplay from '@webSections/VisitOurDisplay';
import BrochureForm from '@webSections/BrochureForm';
import * as Route from '@routes';
import * as styles from './faqs.module.scss';
import { openPopupWidget } from 'react-calendly';
import { render } from '@helpers/markdown';

export interface FaqsProps {
  title: string;
  sections: FaqSection[];
}

export interface FaqSection {
  title: string;
  parts: FaqPart[];
}

export interface FaqPart {
  title: string;
  body: string;
}

const Faqs: React.FC<FaqsProps> = ({ title, sections }) => {
  const faqsRef = React.useRef<HTMLDivElement>(null);
  const calendlyUrl = 'https://calendly.com/ecoliv-sales1/45mins';

  React.useEffect(() => {
    if (faqsRef.current) {
      const calendlyUrlHrefs = faqsRef.current.querySelectorAll(
        `[href="${calendlyUrl}"]`
      );
      calendlyUrlHrefs.forEach(el => {
        el.addEventListener('click', ev => {
          ev.preventDefault();
          openPopupWidget({ url: calendlyUrl });
          return false;
        });
      });
    }
  });

  return (
    <div ref={faqsRef}>
      <PageHeading title="Frequently asked questions" />
      <Container>
        <Box className={styles.faqsInner}>
          <Box className={styles.faqsColumns}>
            {sections.map(section => (
              <Box key={section.title} className={styles.faqsSection}>
                <Box className={styles.faqsSectionHeading}>
                  <h2>{section.title}</h2>
                </Box>
                <Box className={styles.faqsSectionContent}>
                  {section.parts.map(part => (
                    <Accordion key={part.title} title={part.title}>
                      <Box
                        className={styles.faqsSectionContentBody}
                        htmlContent={render(part.body)}
                      />
                    </Accordion>
                  ))}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Container>

      <BrochureForm />

      <VisitOurDisplay />

      <UpNext
        links={[
          { label: 'Our approach', href: Route.ourApproach() },
          { label: 'Modular homes', href: Route.designs() },
        ]}
      />
    </div>
  );
};

export default Faqs;
