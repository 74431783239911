import * as React from 'react';
import { graphql, PageProps } from 'gatsby';
import { PageFaqQuery } from '@generated/GraphqlTypes';
import Base from '@webLayouts/Base/Base';
import Faqs from '@webPages/About/Faqs/Faqs';

const FaqsPage: React.FC<PageProps<PageFaqQuery>> = ({ data }) => {
  const title = data?.faq?.frontmatter?.title || '';

  const seoTitle = data?.faq?.frontmatter?.seoTitle || title || undefined;
  const seoDescription =
    data?.faq?.frontmatter?.seoDescription || data?.faq?.excerpt || undefined;

  const faqs = {
    title,
    sections: (data?.faq?.frontmatter?.sections || []).map((section: any) => {
      return {
        title: section?.title || '',
        parts: (section?.parts || []).map((part: any) => ({
          title: part?.title || '',
          body: part?.body || '',
        })),
      };
    }),
  };

  return (
    <Base theme="light" title={seoTitle} description={seoDescription}>
      <Faqs {...faqs} />
    </Base>
  );
};

export default FaqsPage;

export const query = graphql`
  query PageFaq {
    faq: markdownRemark(
      fields: { collection: { eq: "pages" } }
      frontmatter: { page: { eq: "faq" } }
    ) {
      html
      excerpt
      frontmatter {
        title
        seoTitle
        seoDescription
        sections {
          title
          parts {
            title
            body
          }
        }
      }
    }
  }
`;
